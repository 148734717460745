exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturers-js": () => import("./../../../src/pages/manufacturers.js" /* webpackChunkName: "component---src-pages-manufacturers-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */),
  "component---src-templates-manufacturer-page-js": () => import("./../../../src/templates/manufacturer-page.js" /* webpackChunkName: "component---src-templates-manufacturer-page-js" */),
  "component---src-templates-product-category-page-js": () => import("./../../../src/templates/product-category-page.js" /* webpackChunkName: "component---src-templates-product-category-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-product-subcategory-page-js": () => import("./../../../src/templates/product-subcategory-page.js" /* webpackChunkName: "component---src-templates-product-subcategory-page-js" */)
}

